import React, {Fragment} from 'react';
import { useCallback } from 'react';
import { Link, SimpleForm, required, Edit, Create, Toolbar, SaveButton,
         TextInput, NumberInput, useNotify, useRedirect,
         ArrayInput, SimpleFormIterator, Button,
         FormDataConsumer, ReferenceInput, SelectInput
       } from 'react-admin';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddBoxRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { toast } from 'react-toastify';

const EditWindowingSequenceBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/windowSequence"}>Window Sequences</Link> &nbsp;&gt;{record && record.id > 0 ? ` Edit Windowing Sequence: ${record.windowSequenceName} (${record.id})` : ' Create'}</div>;
};

export const durationUnits = [
    {id : '', value: '', name: ''},
    {id : 'Days', value: 'Days', name: 'Days'},
    {id : 'Weeks', value: 'Weeks', name: 'Weeks'},
    {id : 'Months', value: 'Months', name: 'Months'},
    {id : 'Years', value: 'Years', name: 'Years'},
];

class WindowingSequenceForm extends React.Component {
  render() {
    const validateSpecialCharacters = (name) => {
        const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
        if (specialChars.test(name)) {
            return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
        }
        return undefined;
    }

    return (
        <Fragment>
          <Box display="flex" style={{ width: '98%', paddingLeft: '1%' }}>
            <EditWindowingSequenceBreadCrumb {...this.props} />
          </Box>
          <Box display="flex"style={{ width: '98%', paddingLeft: '1%', float: 'left' }} id="WindowsArray">
            <Box display="flex" justifyContent="center" style={{ width: '28%', paddingLeft: '1%' }}>
              <TextInput options={{ fullWidth: true }} label="Windowing Sequence Name" source="windowSequenceName" validate={[required("Windowing Sequence Name cannot be blank"), validateSpecialCharacters]}  />
            </Box>
            <Box display="flex" justifyContent="center" style={{ width: '28%', paddingLeft: '1%' }}>
                <SelectInput source="status" choices={[
                    { id: 'ACTIVE', name: 'ACTIVE' },
                    { id: 'INACTIVE', name: 'INACTIVE' }
                ]} validate={required("Status is required")} />
            </Box>

          </Box>

        </Fragment>
    )
  }
}
const EditWindowingSequenceTitle = ({ record }) => {
 return <span>{record && record.id > 0 ? `Edit Window Sequence ${record.windowSequenceName} (${record.id})` : 'Create Windowing Sequence'}</span>;
};
const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
//<DeleteWithConfirmButton label="Deactivate" style={{ float: 'right', marginLeft: 'auto' }} confirmTitle="Deactivate Window Sequence" confirmContent="Are you sure you wish to deactivate this windowing sequence?" />
export const WindowingSequenceEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();  
  const save = useCallback(
       async (values) => {
           try {
              document.getElementById("WindowsError").innerHTML = ""               
              const token = localStorage.getItem('tap-token');
              const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization: token},
                body: JSON.stringify(values)
              };
              fetch(process.env.REACT_APP_API_URL + '/windowSequence/' + values.id, requestOptions).then(response => response.json())
              .then(body => {
                console.log("body ", body)
                var errorMessage = ""
                if (body.errors && Object.keys(body.errors).length > 0) {                  
                  if (body.errors['windowSequenceName'])
                    errorMessage += body.errors['windowSequenceName']                  
                  if (body.errors['windowsError'])
                    errorMessage += errorMessage.length > 0 ? ", " + body.errors['windowsError'] : body.errors['windowsError']                    
                  notify(errorMessage, {
                      type: "error",
                      undoable: false,
                      messageArgs: { resource: 'put' }
                  })
                } else if (body.error) {
                    toast.dismiss()
                    errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
                    toast.error(errorMessage, {
                      position: toast.POSITION.BOTTOM_CENTER,
                      closeButton: true,
                      autoClose: 5000
                    });
                } else {                   
                  redirect('/windowSequence');
                  notify("Windowing Sequence Saved", {
                      undoable: false,
                      messageArgs: { resource: 'put' }
                  })
                  return true
                }               
              }).catch(({ status, statusText, headers, body }) => {
                  console.log("Caught status status ", status);
                  console.log("Caught status statusText ", statusText);
                  console.log("Caught status headers ", headers);
                  console.log("Caught status body ", body);
              })
           } catch (error) {
               console.log("Caught error ", error.body.errors)
               if (error.body.errors) {
                  if (error.body.errors.windowsError)
                    document.getElementById("WindowsError").innerHTML = error.body.errors.windowsError
               }
               return error.body.errors;
           } finally {
             console.log("inside finally ")
             return
           }
       },
       [notify, redirect],
  );
  const validateSpecialCharacters = (name) => {
    const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
    if (specialChars.test(name)) {
        return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
    }
    return undefined;
  }
  return (
    <Edit undoable={false} title={<EditWindowingSequenceTitle />}  {...props}>
      <SimpleForm {...props} save={save} toolbar={<CustomToolbar />}>
        <WindowingSequenceForm {...props} />
        <div id="WindowsError" style={{ color: '#f44336',  width: '98%', paddingLeft: '1%', fontSize: '0.75rem;' }}></div>
        <Box display="flex" justifyContent="space-between" style={{ width: '98%', paddingLeft: '1%', float: 'left' }} id="WindowsArray">
          <ArrayInput source="windows" label="Windows">
            <SimpleFormIterator disableReordering addButton={<AddButton  />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
              <FormDataConsumer>
                {({ getSource, scopedFormData }) => {
                 return (
                    <>
                        <TextInput style={{ display: "none"}} label="id" source={getSource('id')} />
                        <TextInput style={{ display: "none"}} label="windowSequenceId" source={getSource('windowSequenceId')} />
                        <ReferenceInput perPage={100} label="Name" reference="lists/transactionalWindows" source={getSource('windowId')} validate={required()}>
                          <SelectInput optionText="label" />
                        </ReferenceInput>
                        <NumberInput min="0" label="Duration" source={getSource('windowDuration')} validate={[required(), validateSpecialCharacters]}  />
                        <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')} choices={durationUnits} validate={required()} resettable />
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Box display="flex" justifyContent="center" style={{ width: '100%', clear: 'both' }}>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export const AddButton = props => (
  <Button {...props} style={{ cursor: 'pointer', color: 'green'}} label="Window" margin="normal">
    <AddIcon label="Window" style={{ cursor: 'pointer', color: 'green'}}  />
  </Button>
);
export const WindowingSequenceCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect(); 
  const save = useCallback(
       async (values) => {
           try {
              document.getElementById("WindowsError").innerHTML = ""              
              const token = localStorage.getItem('tap-token');
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token},
                body: JSON.stringify(values)
              };
              fetch(process.env.REACT_APP_API_URL + '/windowSequence', requestOptions).then(response => response.json())
              .then(body => {
                console.log("body ", body)
                var errorMessage = ""
                if (body.errors && Object.keys(body.errors).length > 0) {                  
                  if (body.errors['windowSequenceName'])
                    errorMessage += body.errors['windowSequenceName']                  
                  if (body.errors['windowsError'])
                    errorMessage += errorMessage.length > 0 ? ", " + body.errors['windowsError'] : body.errors['windowsError']                    
                  notify(errorMessage, {
                       type: "error",
                       undoable: false,
                       messageArgs: { resource: 'post' }
                  })
                } else if (body.error) {
                  toast.dismiss()
                  errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
                  toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    closeButton: true,
                    autoClose: 5000
                  });
                } else {                  
                  redirect('/windowSequence');
                  notify("Windowing Sequence Created", {
                       undoable: false,
                       messageArgs: { resource: 'post' }
                  })
                  return true
                }               
               }).catch(({ status, statusText, headers, body }) => {
                  console.log("Caught status status ", status);
                  console.log("Caught status statusText ", statusText);
                  console.log("Caught status headers ", headers);
                  console.log("Caught status body ", body);
              })
             } catch (error) {
                 if (error.body.errors) {
                    if (error.body.errors.windowsError)
                      document.getElementById("WindowsError").innerHTML = error.body.errors.windowsError
                 }
                 return error.body.errors;
             } finally {
               return
             }
       },
       [notify, redirect],
  );
  const validateSpecialCharacters = (name) => {
    const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
    if (specialChars.test(name)) {
        return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
    }
    return undefined;
  }
  return (
      <Create undoable={false} title={<EditWindowingSequenceTitle />} {...props}>
        <SimpleForm {...props} save={save} defaultValue={{ status: "ACTIVE"}}>
          <WindowingSequenceForm {...props} />
          <div id="WindowsError" style={{ color: '#f44336',  width: '98%', paddingLeft: '1%', fontSize: '0.75rem;' }}></div>
          <Box display="flex" justifyContent="space-between" style={{ width: '98%', paddingLeft: '1%', float: 'left' }} id="WindowsArray">
            <ArrayInput source="windows" label="Windows" defaultValue={[{ windowName: ""}]}>
              <SimpleFormIterator disableReordering addButton={<AddButton  />} removeButton={<RemoveCircleRoundedIcon  style={{ cursor: 'pointer', color: 'red'}} />}>
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                   return (
                      <>
                          <ReferenceInput perPage={100} label="Name" reference="lists/transactionalWindows" source={getSource('windowId')} validate={required()}>
                            <SelectInput optionText="label" />
                          </ReferenceInput>
                          <NumberInput min="0" label="Duration"  source={getSource('windowDuration')} validate={[required(), validateSpecialCharacters]} />
                          <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')} choices={durationUnits} validate={required()} resettable />
                      </>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
          <Box display="flex" justifyContent="center" style={{ width: '100%', clear: 'both' }}>
          </Box>
        </SimpleForm>
      </Create>
  );
};
