import React, {Component} from 'react';
import {
    List, Datagrid, TextField, Filter, DateField, NumberField, ReferenceArrayInput,
    Pagination, TextInput, SelectInput, AutocompleteArrayInput
} from 'react-admin';
import Moment from 'moment-timezone';

const postRowStyle = (record, index) => ({
    minWidth: '200px',
});

export const HistoricalRecordFromRLChoices = [
    {id : true, value: 'Yes', name: 'Yes'},
    {id : false, value: 'No', name: 'No'}
];

export const ProductTypeChoices = [
    {id : 'Digital', value: 'Digital', name: 'Digital'},
    {id : 'Physical', value: 'Physical', name: 'Physical'}
];

export const ProductConfigChoices = [
    {id : 'EHV-HD', value: 'EHV-HD', name: 'EHV-HD'},
    {id : 'EHV-SD', value: 'EHV-SD', name: 'EHV-SD'},
    {id : 'EHV-UHD', value: 'EHV-UHD', name: 'EHV-UHD'},
    {id : 'EHV-3D', value: 'EHV-3D', name: 'EHV-3D'},
    {id : 'PEHV - HD', value: 'PEHV - HD', name: 'PEHV - HD'},
    {id : 'PEHV - SD', value: 'PEHV - SD', name: 'PEHV - SD'},
    {id : 'PEHV - UHD', value: 'PEHV - UHD', name: 'PEHV - UHD'},
    {id : 'PEHV - 3D', value: 'PEHV - 3D', name: 'PEHV - 3D'},
    {id : 'PVOD - HD', value: 'PVOD - HD', name: 'PVOD - HD'},
    {id : 'PVOD - SD', value: 'PVOD - SD', name: 'PVOD - SD'},
    {id : 'PVOD - UHD', value: 'PVOD - UHD', name: 'PVOD - UHD'},
    {id : 'PVOD - 3D', value: 'PVOD - 3D', name: 'PVOD - 3D'},
    {id : 'VOD - HD', value: 'VOD - HD', name: 'VOD - HD'},
    {id : 'VOD - SD', value: 'VOD - SD', name: 'VOD - SD'},
    {id : 'VOD - UHD', value: 'VOD - UHD', name: 'VOD - UHD'},
    {id : 'VOD - 3D', value: 'VOD - 3D', name: 'VOD - 3D'}
];

export const territories = [];

export class PmxCuratedDatesAndPricingFilterComponent extends Component {
    render() {
        const validateSpecialCharacters = (name) => {
            const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
            if (specialChars.test(name)) {
                return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
            }
            return undefined;
        }

        return <Filter {...this.props}>
            <SelectInput label="RL Historical Record" source="isHistorical" choices={HistoricalRecordFromRLChoices} style={{minWidth: 200}} resettable alwaysOn />
            <TextInput type="number" label="Catalog ID" source="catalogId" alwaysOn resettable validate={validateSpecialCharacters} />
            <TextInput label="Catalog Name" source="catalogName" alwaysOn resettable validate={validateSpecialCharacters} />
            <SelectInput label="Product Type" source="productType" choices={ProductTypeChoices} resettable alwaysOn />
            <ReferenceArrayInput allowEmpty={false} label="Territory" reference="pmxCuratedDatesAndPrices/distinctTerritories" source="territories" alwaysOn>
                <AutocompleteArrayInput optionText="name"  />
            </ReferenceArrayInput>
            <ReferenceArrayInput allowEmpty={false} label="Product Platform" reference="pmxCuratedDatesAndPrices/distinctProductPlatforms" source="productPlatforms" alwaysOn>
                <AutocompleteArrayInput optionText="name"  />
            </ReferenceArrayInput>
            <AutocompleteArrayInput allowEmpty={false} label="Product Config" optionText="name"  source="productConfigs" choices={ProductConfigChoices} alwaysOn/>
        </Filter>
    }
}

export const PmxCuratedDatesAndPricingFilter = props => (
    <PmxCuratedDatesAndPricingFilterComponent {...props}  />
);
const PaginationOptions = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const IsHistoricalFlag = ({ record }) => {
    return <span>{record.isHistorical != null && record.isHistorical === true && 'Yes'}</span>
};

const CreatedLocalDateTime = ({ record }) => {
    return <span>{record.createdAt != null && record.createdAt && `${Moment(Moment.utc(record.createdAt).toDate()).tz(Moment.tz.guess()).format('DD-MMM-YYYY')} `}</span>
};
const ModifiedAtAtLocalDateTime = ({ record }) => {
    return <span>{record.modifiedAt != null && record.modifiedAt && `${Moment(Moment.utc(record.modifiedAt).toDate()).tz(Moment.tz.guess()).format('DD-MMM-YYYY, h:mm:ss A z')} `}</span>
};

export const PmxcuratedDatesAndPriceList = props => (

    <List title="PMX Dates And Prices" id="PmxCuratedDatesAndPricingList" filters={<PmxCuratedDatesAndPricingFilter />} bulkActionButtons={false} empty={false} sort={{ field: 'id', order: 'DESC' }} perPage={localStorage.getItem('pmxCuratedDatesAndPrices/perPage') ? localStorage.getItem('pmxCuratedDatesAndPrices/perPage') : 10} pagination={< PaginationOptions />} {...props}>
        <Datagrid rowClick={null} rowStyle={postRowStyle}>
            <TextField source="id" />
            <IsHistoricalFlag source="isHistorical" label="Historical Record from RL?" />
            <TextField source="catalogId" label="Catalog Id" />
            <TextField source="catalogName" label="Catalog" />
            <TextField source="territoryName" label="Territory" />
            <TextField source="productType" label="Product Type" />
            <TextField source="productConfig" label="Product Config"/>
            <TextField source="productPlatform" label="Product Platform" />
            <TextField source="newReleasePEHVStartDate" label="Premium EHVL Start Date" />
            <TextField source="newReleasePEHVStartDateStatus" label="Premium EHVL Start Date Status" />
            {/*<TextField source="newReleasePEHVEndDate" label="New Release PEHV End Date" />*/}
            {/*<TextField source="newReleasePEHVEndDateStatus" label="New Release PEHV End Date Status" />*/}
            <TextField source="newReleaseEHVLStartDate" label="New Release EHVL Start Date" />
            <TextField source="newReleaseEHVLStartDateStatus" label="New Release EHVL Start Date Status" />
            {/*<TextField source="newReleaseEHVLEndDate" label="New Release EHVL End Date" />*/}
            {/*<TextField source="newReleaseEHVLEndStatus" label="New Release EHVL End Status" />*/}
            <TextField source="currencyCodeFlexiPrice" label="Currency Code EHVL Price" />
            <TextField source="newReleaseEHVLTier" label="New Release EHVL Tier" />
            <TextField source="newReleaseEHVLPrice" label="New Release EHVL Price" />
            <TextField source="newtoCatalogEHVLStartDate" label="New to Catalog EHVL Start Date" />
            <TextField source="newtoCatalogEHVLStartDateStatus" label="New to Catalog EHVL Start Date Status" />
            {/*<TextField source="newtoCatalogEHVLEndDate" label="New to Catalog EHVL End Date" />*/}
            {/*<TextField source="newtoCatalogEHVLEndDateStatus" label="New to Catalog EHVL End Date Status" />*/}
            <TextField source="newtoCatalogEHVLTier" label="New to Catalog EHVL Tier" />
            <TextField source="newtoCatalogEHVLPrice" label="New to Catalog EHVL Price" />
            <TextField source="catalogEHVLStartDate" label="Catalog EHVL Start Date" />
            <TextField source="catalogEHVLStartDateStatus" label="Catalog EHVL Start Date Status" />
            {/*<TextField source="catalogEHVLEndDate" label="Catalog EHVL End Date" />*/}
            {/*<TextField source="catalogEHVLEndDateStatus" label="Catalog EHVL End Date Status" />*/}
            <TextField source="catalogEHVLTier" label="Catalog EHVL Tier" />
            <TextField source="catalogEHVLPrice" label="Catalog EHVL Price" />
            <TextField source="presellStartEHVDate" label="Pre-Sell EHVL Start Date" />
            <TextField source="presellStartEHVDateStatus" label="Pre-Sell EHVL Start Date Status" />
            <TextField source="suppressionLiftEHVDate" label="EHVL Suppression Lift Date" />
            <TextField source="suppressionLiftEHVStatus" label="EHVL Suppression Lift Date Status" />
            <TextField source="setPrice" label="EHVL Set Price"/>
            <TextField source="currencyCodeSetPrice" label="Currency Code EHVL Set Price" />
            <TextField source="pvodNewReleaseStartDate" label="TVOD Premium Start Date" />
            <TextField source="pvodNewReleaseStartDateStatus" label="TVOD Premium Start Date Status" />
            {/*<TextField source="pvodNewReleaseEndDate" label="PVOD New Release End Date" />*/}
            {/*<TextField source="pvodNewReleaseEndDateStatus" label="PVOD New Release End Date Status" />*/}
            <TextField source="tvodCurrentStartDate" label="TVOD Current Start Date" />
            <TextField source="tvodCurrentStartDateStatus" label="TVOD Current Start Date Status" />
            {/*<TextField source="tvodCurrentEndDate" label="TVOD Current End Date" />*/}
            {/*<TextField source="tvodCurrentEndDateStatus" label="TVOD Current End Date Status" />*/}
            <TextField source="currencyCodeLicenseFee" label="Currency Code TVOD License Fee" />
            <TextField source="currencyCodeMinDeemedPrice" label="Currency Code TVOD Min Deemed Price" />
            <TextField source="tvodCurrentTier" label="TVOD Current Tier" />
            <TextField source="tvodCurrentLicenseFee" label="TVOD Current License Fee" />
            <TextField source="tvodCurrentMinDeemedPrice" label="TVOD Current Min Deemed Price" />
            <TextField source="tvodLibraryStartDate" label="TVOD Library Start Date" />
            <TextField source="tvodLibraryStartDateStatus" label="TVOD Library Start Date Status" />
            {/*<TextField source="tvodLibraryEndDate" label="TVOD Library End Date" />*/}
            {/*<TextField source="tvodLibraryEndDateStatus" label="TVOD Library End Date Status" />*/}
            <TextField source="tvodLibraryTier" label="TVOD Library Tier" />
            <TextField source="tvodLibraryLicensedFee" label="TVOD Library License Fee" />
            <TextField source="tvodLibraryMinDeemedPrice" label="TVOD Library Min Deemed Price" />
            <TextField source="suppressionLiftVODDate" label="TVOD Suppression Lift Date" />
            <TextField source="suppressionLiftVODStatus" label="TVOD Suppression Lift Date Status" />
            <DateField source="ocad" label="Consumer Announcement Date (OCAD)" />
            <DateField source="physicalDate" label="Physical Date" />
            <TextField source="physicalStatus" label="Physical Status" />
            <NumberField source="physicalRetailPrice" label="Physical Retail Price" />
            <TextField source="currencyCodeWholesalePrice" label="Currency Code Wholesale Price" />
            <NumberField source="physicalWholesalePrice" label="Physical Wholesale Price" />
            <TextField source="presellStartPhysicalDate" label="Pre-Sell Start Physical Date" />
            <TextField source="presellStartPhysicalStatus" label="Pre-Sell Start Physical Status" />
            <CreatedLocalDateTime source="createdAt" label="Created" showTime  />
            <ModifiedAtAtLocalDateTime source="modifiedAt" label="Modified" showTime />
        </Datagrid>
    </List>
);

