// in PostQuickPreviewButton.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { useRefresh, Loading } from 'react-admin';
import Drawer from '@material-ui/core/Drawer';
import { Button } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import IconEdit from '@material-ui/icons/Edit'
import IconPlus from '@material-ui/icons/Add';
import IconRemove from '@material-ui/icons/Clear';
import IconDelete from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextFieldCore from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormGroup from '@material-ui/core/FormGroup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import cloneDeep from 'lodash/cloneDeep';
import InputAdornment from "@material-ui/core/InputAdornment";

class RateCardEditScreen extends Component {
      state = {
        isLoading: true,
        showPanel: false,
        message: undefined,
        rateCardDescription: "",
        rateCardName: "",
        windowSequence: undefined,
        currency: undefined,
        errors: [],
        data: {
          status : 'ACTIVE',
          tierAliases : [],
          windows : [],
        },
        windows: [],
        revShareOptions: ["Rev Share", "Rev Share %", "Rev Share %+", "Non-Rev Share", "VOD PPV", "VOD PPV %", "Hotel", "Rev Share % B", "Rev Share +"],
        priceTypeOptions: ["License Fee", "Min Deemed Price"],
        availableFormats: [],
        headerColumns: [],
        loadCurrencySearch: true,
        loadWindowSequences: true,
        currencyOptions: [],
        windowOptions: []
      };
      updateWindowsInTiers = (windowData) => {
        const { data } = this.state;
        var windows = []
        if (windowData && windowData.windows) {
          windows = windowData.windows.map(window => {
            var newWindow = {}
            newWindow["id"] = {}
            newWindow["id"]["rateCardId"] = data.id
            newWindow["id"]["tapWindowId"] = window.id
            newWindow["percentage"] = undefined
            newWindow["window"] = window
            console.log("newWindow ", newWindow)
            return newWindow
          })
        }
        var tierAliases = data['tierAliases'].map(tierAlias => {
          tierAlias["tiers"] = []
          return tierAlias
        })
        data["tierAliases"] = tierAliases
        data["windows"] = windows
        var headerColumns = this.buildTierHeaderUI(data)
        this.setState({ data, headerColumns });
      }
      loadWindowSequence = () => {
        const { data } = this.state;
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        fetch(process.env.REACT_APP_API_URL + '/windowSequence/' + data['windowSequence'].id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          console.log("Window Data ", responseData)
          this.updateWindowsInTiers(responseData)
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      fetchWindowSequenceSearchResults = (searchTerm, urlParams) => {
          const token = localStorage.getItem('tap-token');
          const requestOptions = {
             method: 'GET',
             headers: { 'Content-Type': 'application/json', Authorization: token},
          };
          if(urlParams === ""){
            urlParams = "filter=" + encodeURIComponent(JSON.stringify({"status": "ACTIVE"}))
          }
          fetch(process.env.REACT_APP_API_URL + '/windowSequence?' + urlParams, requestOptions)
            .then(response => response.json())
            .then(searchResults => {
              var windowOptions = []
              if (searchResults && searchResults.status === 404) {
                windowOptions = []
              } else {
                windowOptions = searchResults
              }
              this.setState({ windowOptions });
            }).catch(ex => {
             console.log("Handled exception loading results for " + searchTerm, ex)
          });
      }
      handleWindowSequenceInputTextChange = (event) => {
        var urlParams = ""
        if (isNaN(event.target.value))
          urlParams = "filter=" + encodeURIComponent(JSON.stringify({"q": event.target.value, "status": "ACTIVE"}))
        else
          urlParams = "filter=" + encodeURIComponent(JSON.stringify({"id": [event.target.value], "status": "ACTIVE"}))
        this.fetchWindowSequenceSearchResults(event.target.value, urlParams)
      }
      handleWindowSequenceChange = (event, inputValue) => {
        var updateWindowSequence = () => {
          const { data, windowOptions, errors } = this.state;
          var windowSequenceChosen = undefined;
          var windowSequence = undefined
          var tierAliases = []
          if (inputValue != null) {
            if (inputValue.windowSequenceName != null) {
              windowSequenceChosen = windowOptions.filter((option) => option.windowSequenceName === inputValue.windowSequenceName)
            } else {
              windowSequenceChosen = windowOptions.filter((option) => option.windowSequenceName === inputValue.toString().substring(0, inputValue.toString().indexOf(" -")))
            }
            data['windowSequence'] = windowSequenceChosen[0]
            this.setState({ windowSequence: windowSequenceChosen[0] });
          } else {
            data['windowSequence'] = undefined
            this.setState({ data, errors, windowSequence });
          }
          delete errors['windowSequence']
          if (windowSequenceChosen != null) {
            this.setState({ errors });
            this.loadWindowSequence()
          } else {
            this.setState({ data, errors, windowSequence, tierAliases });
          }
        }
        const { data } = this.state;
        if (data['windowSequence'] != null && data['windowSequence'].id != null && data['windowSequence'].id > 0) {
          confirmAlert({
            title: 'Are you sure you wish to change the windowing sequence?',
            message: 'All windowing tiers for this rate card will be lost and will have to be reentered.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  updateWindowSequence()
                }
              },
              {
                label: 'No',
                onClick: () => console.log("User cancelled window sequence change")
              }
            ]
          });
        } else {
          updateWindowSequence()
        }
      }
      fetchCurrencySearchResults = (searchTerm, urlParams) => {
          const token = localStorage.getItem('tap-token');
          const requestOptions = {
             method: 'GET',
             headers: { 'Content-Type': 'application/json', Authorization: token},
          };
          fetch(process.env.REACT_APP_API_URL + '/lists/currency?' + urlParams, requestOptions)
            .then(response => response.json())
            .then(searchResults => {
              var currencyOptions = []
              if (searchResults && searchResults.status === 404) {
                currencyOptions = []
              } else {
                currencyOptions = searchResults
              }
              this.setState({ currencyOptions });
            }).catch(ex => {
             console.log("Handled exception loading results for " + searchTerm, ex)
          });
      }
      handleCurrencyInputTextChange = (event) => {
        var urlParams = "filter=" + encodeURIComponent(JSON.stringify({"q": event.target.value}))
        this.fetchCurrencySearchResults(event.target.value, urlParams)
      }
      handleCurrencyChange = (event, inputValue) => {
        const { data, currencyOptions, errors } = this.state;
        var currency = "";
        if (inputValue != null) {
          if (inputValue.label != null) {
            currency = currencyOptions.filter((option) => option.label === inputValue.label)
          } else {
            currency = currencyOptions.filter((option) => option.label === inputValue.toString().substring(0, inputValue.toString().indexOf(" -")))
          }
          data['currency'] = currency[0]
          this.setState({
            currency: currency[0]
          });
        } else {
          data['currency'] = undefined
          this.setState({
            currency: undefined
          });
        }
        delete errors['currency']
        this.setState({ errors, data });
      }

  handlePriceTypeChange = (event, inputValue) => {
    var updatePriceType = () => {
      const { data, errors } = this.state;
      data['priceType'] = inputValue
      errors["priceType"] = undefined
      this.setState({
        priceType: inputValue,
      });

      removeTierRows();
    }

    var removeTierRows = () => {
      const { data } = this.state;
      var tierAliases = data["tierAliases"]
      var newTierAliases = cloneDeep(tierAliases);
      if (tierAliases.length > 0) {
        data["tierAliases"].forEach(function(name, tierAliasesIndex) {
          var spliceCount = 0
          data["tierAliases"][tierAliasesIndex]["tiers"].forEach(function(name, tierIndex) {
              newTierAliases[tierAliasesIndex]["tiers"].splice((tierIndex-spliceCount),1)
              spliceCount++
          })
        })
      }

      data["tierAliases"] = newTierAliases
      var headerColumns = this.buildTierHeaderUI(data)
      this.setState({ data, headerColumns });
    }
    const { data } = this.state;
    if (data['priceType'] != null) {
      confirmAlert({
        title: 'Are you sure you wish to change the Rate Card Price Type?',
        message: 'All windowing tiers for this Rate Card will be lost and will have to be re-entered.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              updatePriceType()
            }
          },
          {
            label: 'No',
            onClick: () => console.log("User cancelled Price Type change")
          }
        ]
      });
    } else {
      updatePriceType()
    }
  }
      updateTierAliases = (tierAliases) => {
        const { data } = this.state;
        data["tierAliases"] = tierAliases
        var headerColumns = this.buildTierHeaderUI(this.state.data)
        this.setState({ data, headerColumns });
      }
      toggleStatus = (event) => {
        const { data } = this.state;
        data['status'] = data.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
        this.setState({ data });
      }
      buildTierHeaderUI = (data) => {
        const { availableFormats } = this.state;
        const changeTierAliasName = tierAlias => (event) => {
          const { data } = this.state;
          var tierAliases = data["tierAliases"]
          for(var i=0; i < tierAliases.length; i++){
            if (tierAliases[i] && tierAliases[i].index === tierAlias.index) {
              tierAliases[i].tierAliasName = event.target.value
            }
          }
          this.updateTierAliases(tierAliases)
        };
        const changeTierName = (tierAlias, tierNumber) => (event) => {
          const { data, errors } = this.state;
          var tierAliases = data["tierAliases"]

          console.log(errors)
          data["tierAliases"][tierAlias.index].errors = errors

          for(var i=0; i < tierAliases.length; i++){
            if (tierAliases[i] && tierAliases[i].index === tierAlias.index) {
              for(var j=0; j < tierAliases[i].tiers.length; j++){
                if (tierAliases[i].tiers[j].id.tierNumber === tierNumber) {

                  const tierAliasError = this.validateSpecialCharacters(event.target.value);
                  if (this.validateSpecialCharacters(event.target.value))
                    data["tierAliases"][tierAlias.index].errors["tierNumber_" + tierAlias.index + "_" + tierNumber] = tierAliasError
                  else {
                    data["tierAliases"][tierAlias.index].errors["tierNumber_" + tierAlias.index + "_" + tierNumber] = undefined
                  }
                  tierAliases[i].tiers[j].tierName = event.target.value
                }
              }
            }
          }
          this.updateTierAliases(tierAliases)


        };
        const changeTierPrice = (tierAlias, tierNumber, tapWindowId) => (event) => {
          const { data } = this.state;
          var tierAliases = data["tierAliases"]
          for(var i=0; i < tierAliases.length; i++) {
            //if (tierAliases[i] && tierAliases[i].index === tierAlias.index) {
              for(var j=0; j < tierAliases[i].tiers.length; j++) {
                if (tierAliases[i].tiers[j].id.tierNumber === tierNumber && tierAliases[i].tiers[j].id.tapWindowId === tapWindowId) {
                  tierAliases[i].tiers[j].price = event.target.value
                }
              }
            //}
          }
          this.updateTierAliases(tierAliases)
        };
        const changeTierAliasFormat = tierAlias => (event, newValue) => {
          const { data } = this.state;
          var tierAliases = data["tierAliases"]
          for(var i=0; i < tierAliases.length; i++){
            if (tierAliases[i] && tierAliases[i].index === tierAlias.index) {
              tierAliases[i].tierAliasFormat = newValue
            }
          }
          this.updateTierAliases(tierAliases)
        };
        const changeWindowsPercentage = tierAlias => (event, newValue) => {
          const { data } = this.state;
          var windows = data["windows"]
          for(var i=0; i < windows.length; i++){
            if (windows[i].id === tierAlias.id) {
              if (event.target.value)
                windows[i].percentage = event.target.value.replace("%","")
              else
                windows[i].percentage = event.target.value
            }
          }
          data["windows"] = windows
          var headerColumns = this.buildTierHeaderUI(data)
          this.setState({ data, headerColumns });
        };
        var headerColumns = []
        var tierWindowPricing = []
        var tierWindowPricingByTierAlias = []
        var self = this
        data["tierAliases"].map(function(name, index) {
          var tierNames = []
          var tierNameFields = []
          data["tierAliases"][index].tiers.forEach(tier => {
            if (!tierWindowPricing[tier.id.tapWindowId]) {
              tierWindowPricing[tier.id.tapWindowId] = []
            }
            if (index === 0 && !tierNames[tier.id.tierNumber]) {
              tierNameFields.push(<Button
                key={'removeTierButton-' + tier.id.tapWindowId + "-" + tier.id.tierNumber}
                className="rateCardRowRemoveTier"
                label=""
                onClick={() => self.removeTierRow(tier.id.tierNumber)}>
                  <IconRemove />
              </Button>)
            }
            if (!tierNames[tier.id.tierNumber]) {
              if (index > 0) {
                tierNameFields.push(<div key={'tierNamePlaceholder-' + tier.id.tapWindowId + "-" + tier.id.tierNumber} className="rateCardRowRemoveTierPlaceholder"></div>)
              }
              tierNames[tier.id.tierNumber] = tier.id.tierNumber
              tierNameFields.push(<FormControl className="tierRowTierNumberInput" key={'tier-' + tier.id.tierNumber} fullWidth error={data["tierAliases"][index].errors && data["tierAliases"][index].errors["tierNumber_" + index + "_" + tier.id.tierNumber] !== undefined}>
                  <TextFieldCore
                    variant="outlined"
                    margin="normal"
                    fullWidth={false}
                    size="small"
                    label="Tier *"
                    type="string"
                    onChange={changeTierName(data["tierAliases"][index], tier.id.tierNumber)}
                    value={tier.tierName ? tier.tierName : ""}
                  />
                  <FormHelperText>{data["tierAliases"][index].errors && data["tierAliases"][index].errors["tierNumber_" + index + "_" + tier.id.tierNumber]}</FormHelperText>
                </FormControl>)
            }
            if (!tierWindowPricingByTierAlias[tier.id.tapWindowId + "_" + tier.id.tierNumber]) {
              //console.log("Adding price to " + tier.id.tapWindowId + "_" + tier.id.tierNumber + " ")
              tierWindowPricingByTierAlias[tier.id.tapWindowId + "_" + tier.id.tierNumber] = tier.id.tapWindowId + "_" + tier.id.tierNumber
              tierWindowPricing[tier.id.tapWindowId].push(<div key={'tierPricePlaceholder-' + tier.id.tapWindowId + "-" + tier.id.tierNumber} className="rateCardRowRemoveTierPricePlaceholder"></div>)
              tierWindowPricing[tier.id.tapWindowId].push(<FormControl className="tierRowTierNumberInput" key={'tierPrice-' + tier.id.tapWindowId + "-" + tier.id.tierNumber}  fullWidth error={data["tierAliases"][index].errors && data["tierAliases"][index].errors["tierPrice_" + tier.id.tapWindowId + "_" + tier.id.tierNumber] !== undefined}>
                  <TextFieldCore
                    variant="outlined"
                    margin="normal"
                    fullWidth={false}
                    size="small"
                    label="Price *"
                    onChange={changeTierPrice(data["tierAliases"][index], tier.id.tierNumber, tier.id.tapWindowId)}
                    value={tier.price ? tier.price : ""}
                  />
                <FormHelperText>{data["tierAliases"][index].errors && data["tierAliases"][index].errors["tierPrice_" + tier.id.tapWindowId + "_" + tier.id.tierNumber]}</FormHelperText>
                </FormControl>)
            }
          });
          return headerColumns.push(
            <Box className="rateCardHeaderRowColumn" key={index}>
              <FormGroup row>
                <Button
                  className="rateCardHeaderRowRemoveColumn"
                  label=""
                  onClick={() => self.removeTierFormat(index)}>
                    <IconRemove />
                </Button>
                <FormControl className="tierNameInput" fullWidth error={data["tierAliases"][index].errors && data["tierAliases"][index].errors["tierAliasName"] !== undefined}>
                  <TextFieldCore
                    variant="outlined"
                    margin="normal"
                    fullWidth={false}
                    size="small"
                    label="Tier Name *"
                    onChange={changeTierAliasName(data["tierAliases"][index])}
                    value={data["tierAliases"][index].tierAliasName ? data["tierAliases"][index].tierAliasName : ""}
                  />
                  <FormHelperText>{data["tierAliases"][index].errors && data["tierAliases"][index].errors["tierAliasName"]}</FormHelperText>
                </FormControl>
                <FormControl className="tierFormatControl" fullWidth error={data["tierAliases"][index].errors && data["tierAliases"][index].errors["format"] !== undefined}>
                 <Autocomplete
                    multiple
                    filterSelectedOptions
                    value={data["tierAliases"][index].tierAliasFormat ? data["tierAliases"][index].tierAliasFormat : [""]}
                    autoHighlight={true}
                    autoSelect={false}
                    clearOnBlur={false}
                    disableClearable={true}
                    onChange={changeTierAliasFormat(data["tierAliases"][index])}
                    options={availableFormats}
                    renderInput={(params) => (
                      <TextFieldCore
                        {...params}
                        label="Format *"
                        variant="outlined"
                        error={data["tierAliases"][index].errors && data["tierAliases"][index].errors["format"] !== undefined}
                        />
                      )}
                    />
                 <FormHelperText>{data["tierAliases"][index].errors && data["tierAliases"][index].errors["format"]}</FormHelperText>
                </FormControl>
                {tierNameFields && tierNameFields.map(tierNameField => tierNameField)}
              </FormGroup>
          </Box>)
        })
        data["windows"].map(function(name, index) {
          return headerColumns.push(
            <Box className="rateCardHeaderRowColumn" key={'window-' + data["windows"][index].window.windowName + '-' + index}>
              <span>{data["windows"][index].window.windowName}<br/>
              {data["windows"][index].window.windowDuration} {data["windows"][index].window.windowDurationUnit}
              <br/></span>
              <FormGroup row>
                {<FormControl className="rateCardPercentageInput" fullWidth error={data["windows"][index].errors && data["windows"][index].errors["percentage"] !== undefined}>
                  <TextFieldCore
                    variant="outlined"
                    margin="normal"
                    fullWidth={false}
                    size="small"
                    suffix="%"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    label={data["revShareType"] && data["revShareType"].indexOf('%') > 0 ? "Percentage *" : "Percentage"}
                    disabled={!data["revShareType"] || data["revShareType"].indexOf('%') < 0}
                    onChange={changeWindowsPercentage(data["windows"][index])}
                    value={data["windows"][index].percentage ? data["windows"][index].percentage : ""}
                  />
                <FormHelperText className="rateCardPercentageInputHelperText">{data["windows"][index].errors && data["windows"][index].errors["percentage"]}</FormHelperText>
                </FormControl>
              }
              {tierWindowPricing && tierWindowPricing[data["windows"][index].window.id] && tierWindowPricing[data["windows"][index].window.id].map(tierWindowPrice => tierWindowPrice)}
              </FormGroup>
          </Box>)
        })
        return headerColumns
      }
      removeTierFormat = (index) => {
        confirmAlert({
          title: 'Are you sure you wish to remove this tier format column?',
          message: 'All tier information for this tier format column will be lost and will have to be reentered.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                const { data } = this.state;
                var tierAliases = data["tierAliases"]
                tierAliases.splice(index, 1)
                var newIndex = 0
                tierAliases.map(function(name, index) {
                  return tierAliases[index].index = newIndex++
                })
                data["tierAliases"] = tierAliases
                var headerColumns = this.buildTierHeaderUI(data)
                this.setState({ data, headerColumns });
              }
            },
            {
              label: 'No',
              onClick: () => console.log("User cancelled removing the tier format")
            }
          ]
        });
      }
      removeTierRow = (tierNumber) => {
        confirmAlert({
          title: 'Are you sure you wish to remove this tier row?',
          message: 'All tier information for this tier row will be lost and will have to be reentered.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                const { data } = this.state;
                var tierAliases = data["tierAliases"]
                var newTierAliases = cloneDeep(tierAliases);
                if (tierAliases.length > 0) {
                  data["tierAliases"].forEach(function(name, tierAliasesIndex) {
                    var spliceCount = 0
                    data["tierAliases"][tierAliasesIndex]["tiers"].forEach(function(name, tierIndex) {
                      var tier = data["tierAliases"][tierAliasesIndex]["tiers"][tierIndex]
                      if (tier.id.tierNumber === tierNumber) {
                        //console.log("Remove tierAliasesIndex " + tierAliasesIndex + " with tier number " + tierNumber + " at tier index " + (tierIndex-spliceCount), data["tierAliases"][tierAliasesIndex]["tiers"][tierIndex])
                        newTierAliases[tierAliasesIndex]["tiers"].splice((tierIndex-spliceCount),1)
                        spliceCount++
                      }
                    })
                  })
                }
                data["tierAliases"] = newTierAliases
                var headerColumns = this.buildTierHeaderUI(data)
                this.setState({ data, headerColumns });
              }
            },
            {
              label: 'No',
              onClick: () => console.log("User cancelled removing the tier format")
            }
          ]
        });
      }
      addTierFormat = () => {
        const { data } = this.state;
        var tierAliases = data["tierAliases"]
        var tiersToAdd = []
        var sortOrder = 1
        if (tierAliases.length > 0) {
          console.log("tierAliases.length " + tierAliases.length)
          var tierAlias = tierAliases[tierAliases.length-1]
          console.log("tierAlias ", tierAlias)
          console.log("tierAlias.sortOrder ", tierAlias.sortOrder)
          if (tierAlias.sortOrder) {
            sortOrder = parseInt(tierAlias.sortOrder) + 1
            console.log("sortOrder ", sortOrder)
          }
          var tierSortOrder = 1
          tierAlias.tiers.forEach(tier => {
            var tierToAdd = {}
            var id = {
              "tapWindowId": tier.id.tapWindowId,
              "tierNumber": tier.id.tierNumber,
            }
            tierToAdd["id"] = id
            if (parseInt(tier.tierName) >= 100)
              tierToAdd["tierName"] = (parseInt(tier.tierName) - tier.id.tierNumber) * 10 + tier.id.tierNumber
            else
              tierToAdd["tierName"] = parseInt(tier.tierName) + 100
            tierToAdd["price"] = tier.price
            tierToAdd["tierSortOrder"] = tierSortOrder++
            tiersToAdd.push(tierToAdd)
          });
        }
        var newTierAlias = {
          "index" : tierAliases.length,
          "rateCardId": this.props.id,
          "tierAliasName": "",
          "tierAliasFormat": [],
          "tiers" : tiersToAdd,
          "sortOrder" : sortOrder
        }
        console.log("newTierAlias ", newTierAlias)
        tierAliases.push(newTierAlias)
        data["tierAliases"] = tierAliases
        var headerColumns = this.buildTierHeaderUI(data)
        console.log("data ", data)
        this.setState({ data, headerColumns });
      }
      addTierRow = () => {
        const { data } = this.state;
        var tierAliases = data["tierAliases"]
        var windows = data["windows"]
        var lastSortOrder = 1
        if (tierAliases.length > 0) {
          var tierAliasIndex = 0
          var lastTierName = 0
          tierAliases.forEach(tieralias => {
            var lastTierNumber = 0
            if (tierAliasIndex === 1)
              lastTierName = 100
            else if (tierAliasIndex > 1)
              lastTierName = lastTierName * 10
            tierAliasIndex++
            if (tieralias.tiers && tieralias.tiers.length > 0) {
              lastTierNumber = tieralias.tiers.reduce((max, arr)=> {
                  return arr.id.tierNumber > max ? arr.id.tierNumber : max
                },-Infinity)
              //lastTierNumber = tieralias.tiers[tieralias.tiers.length-1].id.tierNumber
              lastTierName = tieralias.tiers[tieralias.tiers.length-1].tierName
              lastSortOrder = tieralias.tiers[tieralias.tiers.length-1].sortOrder
            }
            console.log(" lastSortOrder ", lastSortOrder)
            windows.forEach(window => {
              var tierToAdd = {}
              var id = {
                "tierAliasId": tieralias.id,
                "tapWindowId": window.id.tapWindowId,
                "tierNumber": parseInt(lastTierNumber) + 1,
              }
              tierToAdd["id"] = id
              tierToAdd["tierName"] = parseInt(lastTierName) + 1
              tierToAdd["price"] = 0
              tierToAdd["sortOrder"] = lastSortOrder + 1
              tieralias.tiers.push(tierToAdd)
            });
          });
        }
        console.log("tierAliases ", data["tierAliases"])
        data["tierAliases"] = tierAliases
        var headerColumns = this.buildTierHeaderUI(data)
        this.setState({ data, headerColumns });
      }
      handleClick = () => {
        this.setState({
          errors:[],
          showPanel: true,
          isLoading: true,
          data: {
            tierAliases : [], windows : [], status : 'ACTIVE'
          },
          windows: [],
          loadCurrencySearch: true,
          loadWindowSequences: true,
          windowSequence: undefined,
          currency: undefined,
          availableFormats: [],
          headerColumns: [],
          currencyOptions: [],
          windowOptions: [],
          rateCardDescription: "",
          rateCardName: ""
        });
        var errors = []
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        fetch(process.env.REACT_APP_API_URL + '/lists/format', requestOptions)
          .then(response => response.json())
          .then(searchResults => {
            if (searchResults && searchResults.status === 404) {
              this.setState({ availableFormats:[] });
            } else {
              console.log("set avail formats to searchResults ", searchResults)
              this.setState({ availableFormats: searchResults.map(it => it.label) });
            }
          }).catch(ex => {
           console.log("Exception loading format list ", ex)
        });
        this.fetchCurrencySearchResults("","")
        this.fetchWindowSequenceSearchResults("", "");
        if (this.props.id) {
          fetch(process.env.REACT_APP_API_URL + '/rateCard/' + this.props.id, requestOptions)
            .then(response => response.json())
            .then(responseData => {
              if (responseData["currency"] !== null && responseData["currency"] !== "")
                responseData["currency"] = { label: responseData["currency"], value: responseData["currency"], searchableTitle: responseData["currency"] }
              else
                responseData["currency"] = null
              responseData["tierAliases"].map(function(name, index) {
                responseData["tierAliases"][index].tierAliasFormat = responseData["tierAliases"][index].tierAliasFormat.split(",")
                return responseData["tierAliases"][index].index = index
              });
              responseData["windows"].sort(function (a, b) {
                  return a.id.windowId - b.id.windowId;
              });
              responseData["tierAliases"].sort(function (a, b) {
                  return a.sortOrder - b.sortOrder;
              });
              var headerColumns = this.buildTierHeaderUI(responseData)
              this.setState({
                rateCardDescription: responseData["rateCardDescription"],
                rateCardName: responseData["rateCardName"],
                windowSequence: responseData["windowSequence"],
                currency: responseData["currency"],
                data: responseData,
                headerColumns: headerColumns,
                errors: errors,
                showPanel: true,
                isLoading: false
              });
          }).catch(ex => {
           console.log("Exception loading format list ", ex)
          });
        } else {
          //var headerColumns = this.buildTierHeaderUI(this.state.data)
          this.setState({
            errors: [],
            showPanel: true,
            isLoading: false,
            windowSequence: undefined,
            headerColumns: [],
          });
        }
      };
      validateSpecialCharacters = (name) => {
          const specialChars = /[`!@#$%^&*[()_+={}\];':"\\|,.<>/?~¡¨°Ñ-]/
          if (specialChars.test(name)) {
            return 'Input may not contain [`!@#$%^&*[()_+={}];\':"\\|,.<>/?~¡¨°Ñ-]';
          }
          if (name.toUpperCase().indexOf("COPY") > -1) {
            return 'Input may not COPY';
          }
          return undefined;
      }
      handleRateCardChange = (event) => {
        const { data, errors} = this.state;
        data[event.target.name] = event.target.value
        this.setState({ data });
        if (event.target.name === "rateCardName") {
          var rateCardNameError = this.validateSpecialCharacters(event.target.value)
          if (rateCardNameError)
            errors["rateCardName"] = rateCardNameError
          else {
            errors["rateCardName"] = undefined
          }
          this.setState({ rateCardName:event.target.value, errors: errors });
        }
        if (event.target.name === "rateCardDescription") {
          var rateCardDescriptionError = this.validateSpecialCharacters(event.target.value)
          if (rateCardDescriptionError)
            errors["rateCardDescription"] = rateCardDescriptionError
          else {
            errors["rateCardDescription"] = undefined
          }
          this.setState({ rateCardDescription:event.target.value, errors: errors });
        }
      }
      handleCloseClick = (event, reason) => {
        console.log("reason ", reason)
        if (reason !== 'backdropClick') {
            this.setState({ showPanel:false });
        }
      }
      inActivateRateCard = () => {
        confirmAlert({
          title: 'Are you sure you wish to inactivate this rate card?',
          message: 'This rate card will be put in an inactive state',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  isLoading: true
                });
                const token = localStorage.getItem('tap-token');
                const requestOptions = {
                   method: 'DELETE',
                   headers: { 'Content-Type': 'application/json', Authorization: token}
                };
                fetch(process.env.REACT_APP_API_URL + '/rateCard/' + this.props.id, requestOptions).then(response => response.json())
                .then(body => {
                  var errors = []
                  var successMessage = <div><div className="toastTitle">Success</div><div className="toastMainMessage">Rate Card Inactivated</div></div>
                  toast.success(successMessage, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    closeButton: true,
                    autoClose: 5000
                  });
                  this.setState({
                    data: body,
                    errors: errors,
                    isLoading: false
                  });
                  document.getElementById('REFRESH').click()
                }).catch(({ status, statusText, headers, body }) => {
                    console.log("Caught status status ", status);
                    console.log("Caught status statusText ", statusText);
                    console.log("Caught status headers ", headers);
                    console.log("Caught status body ", body);
                })
              }
            },
            {
              label: 'No',
              onClick: () => console.log("User cancelled rate card inactivate")
            }
          ]
        });
      }
      validateFieldsAndSave = () => {
        const { data } = this.state;
        var errors = []
        if (data.rateCardName == null || data.rateCardName === "") {
          errors["rateCardName"] = "Rate Card Name cannot be blank"
        } else {
          var rateCardNameError = this.validateSpecialCharacters(data.rateCardName)
          if (rateCardNameError)
            errors["rateCardName"] = rateCardNameError
        }
        /*
        if (data.rateCardDescription == null || data.rateCardDescription === "") {
          errors["rateCardDescription"] = "Rate Card Description is mandatory and cannot be blank"
        } else {
          var rateCardDescriptionError = this.validateSpecialCharacters(data.rateCardDescription)
          if (rateCardDescriptionError)
            errors["rateCardDescription"] = rateCardDescriptionError
        }
        */
        if (!data.windowSequence) {
          errors["windowSequence"] = "Window Sequence is mandatory and cannot be blank"
        } else {
          data.windowSequenceId = data.windowSequence.id
        }
        if (!data.currency) {
          errors["currency"] = "Currency is mandatory and cannot be blank"
        }
        if (!data.priceType ) {
          errors["priceType"] = "Price Type is mandatory and cannot be blank"
        }
        if (!data.revShareType ) {
          errors["revShareType"] = "Rev Share Type is mandatory and cannot be blank"
        }

        const allTierAliasNames = {}
        const allTierNames = {}
        if (data["windows"] && data["windows"][0] && data["windows"][0].id) {
          var tapWindowId = data["windows"][0].id.tapWindowId
          data.tierAliases.forEach(tierAlias => {
            if(!allTierAliasNames[tierAlias.tierAliasName])
              allTierAliasNames[tierAlias.tierAliasName] = 1
            else
              allTierAliasNames[tierAlias.tierAliasName] = parseInt(allTierAliasNames[tierAlias.tierAliasName])+1
            tierAlias.tiers.forEach(tier => {
              if (tapWindowId === tier.id.tapWindowId) {
                if(!allTierNames[tier.tierName])
                  allTierNames[tier.tierName] = 1
                else
                  allTierNames[tier.tierName] = parseInt(allTierNames[tier.tierName])+1
              }
            });
          });
        }

        data.tierAliases.forEach(tierAlias => {
          data["tierAliases"][tierAlias.index].errors = []

          if (!tierAlias.tierAliasFormat || tierAlias.tierAliasFormat.length === 0) {
            errors["tierAliaseNames"] = "Tier Format is Required"
            data["tierAliases"][tierAlias.index].errors["format"] = "Tier Format is Required"
          }
          // check for dupe tier names within same alias
          if (!tierAlias.tierAliasName || tierAlias.tierAliasName === "") {
            errors["tierAliaseNames"] = "Tier Name is required"
            data["tierAliases"][tierAlias.index].errors["tierAliasName"] = "Tier Name is required"
          } else if (allTierAliasNames[tierAlias.tierAliasName] > 1) {
            errors["tierAliaseNames"] = "Duplicate Tier Format Name"
            data["tierAliases"][tierAlias.index].errors["tierAliasName"] = "Duplicate Tier name"
          }
          tierAlias.tiers.forEach(tier => {
            // check for dupe tier names within other tier aliases
            if (allTierNames[tier.tierName] > 1 ) {
                errors["tierAliaseNames"] = "Duplicate Tier Name"
                data["tierAliases"][tierAlias.index].errors["tierNumber_" + tierAlias.index + "_" + tier.id.tierNumber] = "Duplicate Tier Number"
            }

            if (!tier.tierName || tier.tierName === "") {
                errors["tierAliaseNames"] = "Tier Number is required"
                data["tierAliases"][tierAlias.index].errors["tierNumber_" + tierAlias.index + "_" + tier.id.tierNumber] = "Tier Number is required"
            }

            var specialChars = /[`!@#$%^&*[()_+={}\];':"\\|,.<>/?~¡¨°Ñ-]/
            if (specialChars.test(tier.tierName)) {
              errors["tierAliaseNames"] = 'Input may not contain [`!@#$%^&*[()_+={}];\':"\\|,.<>/?~¡¨°Ñ-]'
              data["tierAliases"][tierAlias.index].errors["tierNumber_" + tierAlias.index + "_" + tier.id.tierNumber] = 'Input may not contain [`!@#$%^&*[()_+={}];\':"\\|,.<>/?~¡¨°Ñ-]'
            } else {
              data["tierAliases"][tierAlias.index].errors["tierNumber_" + tierAlias.index + "_" + tier.id.tierNumber] = undefined
            }

            var regex = /^\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
            if (!tier.price || !regex.test(tier.price.toString())) {
              errors["tierPrices"] = "Valid tier price is required"
              data["tierAliases"][tierAlias.index].errors["tierPrice_" + tier.id.tapWindowId + "_" + tier.id.tierNumber] = "Valid Tier Price is required"
            } else {
              data["tierAliases"][tierAlias.index].errors["tierPrice_" + tier.id.tapWindowId + "_" + tier.id.tierNumber] = undefined
              tier.price = tier.price.toString().replace(",", "")
            }
          })
        })

        if (data.revShareType && data.revShareType.indexOf('%') > 0) {
          var windowsIndex = 0;
          data.windows.forEach(window => {
            data["windows"][windowsIndex].errors = []
            if (!window.percentage) {
                errors["windowsPercentage"] = "Percentage is required"
                data["windows"][windowsIndex].errors["percentage"] = "Percentage is required"
            }
            windowsIndex++
          })
        }

        if (Object.keys(errors).length === 0) {
          this.setState({
            errors: errors,
            isLoading: true
          });
          if (data.status === 'NEEDSATTENTION') {
            data.status = 'ACTIVE'
          }
          data.currency = data.currency.searchableTitle
          data.tierAliases.forEach(tierAlias => {
            tierAlias.tierAliasFormat = tierAlias.tierAliasFormat.join(",")
          });
          var payload = JSON.parse(JSON.stringify(data))
          const token = localStorage.getItem('tap-token');
          const requestOptions = {
             method: this.props.id ? 'PUT' : 'POST',
             headers: { 'Content-Type': 'application/json', Authorization: token},
             body: JSON.stringify(payload)
          };
          fetch(process.env.REACT_APP_API_URL + '/rateCard' + (this.props.id ? '/' + this.props.id : ''), requestOptions).then(response => response.json())
          .then(body => {
              console.log("body ", body)
              const keys = body.errors ? Object.keys(body.errors) : [];
              if (body.httpCode === 500 || (body.errors && keys && keys.length > 0)) {
                var errors = []
                for (const key in body.errors) {
                  errors[key] = body.errors[key]
                }
                console.log("errors ", errors)

                if (body["currency"] !== null && body["currency"] !== "")
                  body["currency"] = { label: body["currency"], value: body["currency"], searchableTitle: body["currency"] }
                else
                  body["currency"] = null
                if (body["tierAliases"]) {
                  body["tierAliases"].map(function(name, index) {
                    body["tierAliases"][index].tierAliasFormat = body["tierAliases"][index].tierAliasFormat.split(",")
                    return body["tierAliases"][index].index = index
                  });
                }

                var errorMessageString = "Error Saving Rate Card"
                if (body.error && body.error.message) {
                  console.log(" nested index ", body.error.message.toString().indexOf(";nested"))
                  if (body.error.message.toString().indexOf("; nested") > 0) {
                    errorMessageString = body.error.message.toString().substring(0, body.error.message.toString().indexOf("; nested"))
                    if (errorMessageString.indexOf(" at ") > 0) {
                      errorMessageString = errorMessageString.substring(0, errorMessageString.indexOf("at"))
                    }
                  } else {
                    errorMessageString = body.error.message.toString()
                  }
                }
                console.log("errorMessageString ", errorMessageString)

                var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{errorMessageString}</div></div>
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: false
                });

                this.setState({
                  data: body,
                  errors: errors,
                  isLoading: false
                });
                // refresh the page if access is denied
                if (errorMessageString === 'Access is denied') {
                  document.getElementById('REFRESH').click()
                }
              } else {
                errors = []
                var successMessage = <div><div className="toastTitle">Success</div><div className="toastMainMessage">Rate Card Saved</div></div>
                toast.success(successMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: 5000
                });
                if (body["currency"] !== null && body["currency"] !== "")
                  body["currency"] = { label: body["currency"], value: body["currency"], searchableTitle: body["currency"] }
                else
                  body["currency"] = null
                body["tierAliases"].map(function(name, index) {
                  body["tierAliases"][index].tierAliasFormat = body["tierAliases"][index].tierAliasFormat.split(",")
                  return body["tierAliases"][index].index = index
                });
                console.log("succes errors ", errors)
                this.setState({
                  data: body,
                  errors: errors,
                  isLoading: false
                });
                document.getElementById('REFRESH').click()
              }
          }).catch(({ status, statusText, headers, body }) => {
              console.log("Caught status status ", status);
              console.log("Caught status statusText ", statusText);
              console.log("Caught status headers ", headers);
              console.log("Caught status body ", body);
          })
        } else {
          console.log("data ", data)
          console.log("errors ", errors)
          var headerColumns = this.buildTierHeaderUI(data)
          this.setState({ errors, data, headerColumns });
        }

      }
      render() {
          var self = this;
          function Refresh() {
              const refresh = useRefresh();
              const refreshList = function() {
                self.setState({
                  errors: errors,
                  isLoading: false,
                  showPanel: false,
                }, refresh());
              }
              return (
                <>
                  <Button id='REFRESH' style={{ display: "none" }} label="Refresh" onClick={() => refreshList()} />
                </>
              )
          }
          const { isLoading, showPanel, rateCardName, errors, data, revShareOptions, priceTypeOptions, headerColumns, currencyOptions, windowOptions } = this.state;
          return (
          <Fragment>
              <div id="RateCardEditIcon" className="RateCardEditIcon">
                  {this.props.id && this.props.id > 0 && <Button onClick={this.handleClick}>
                      <IconEdit title={'Edit'} />
                  </Button>}
                  {!this.props.id && <Button onClick={this.handleClick} title={`Create`} label={`Create`}>
                      <IconPlus title={`Create`} />
                  </Button>}
              </div>
              <Drawer
                  anchor="right"
                  open={showPanel}
                  onClose={this.handleCloseClick}
                  disableEscapeKeyDown={true}
              >
                  <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                    <Fragment>
                          <Typography style={{ fontSize: "28px "}} variant="subtitle1" color="inherit" >
                          {this.props.id && this.props.id > 0 ? 'Edit' : 'Create'} Rate Card</Typography>
                          <hr />
                          { isLoading ? <Loading /> : <> <Box display="flex" justifyContent="space-between">
                            <Box width="66%" style={{ marginBottom: '1%'}}>
                               <FormControl error={errors && errors["rateCardName"] !== undefined} fullWidth>
                                <TextFieldCore
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth={false}
                                  size="small"
                                  label="Name *"
                                  name="rateCardName"
                                  onChange={this.handleRateCardChange}
                                  error={errors && errors["rateCardName"] !== undefined}
                                  value={rateCardName}
                                />
                                <FormHelperText>{errors && errors["rateCardName"]}</FormHelperText>
                              </FormControl>
                            </Box>
                            <Box width="32%">
                            {this.props.id && this.props.id > 0 ?  <FormControlLabel
                                control={<Switch disabled={data.status === 'NEEDSATTENTION'} checked={data.status !== 'INACTIVE'} name="status" id="status" color="primary" onChange={this.toggleStatus} />}
                                label={ data.status }
                              />
                            : <FormControlLabel
                            control={<Switch disabled={true} checked={data.status !== 'INACTIVE'} name="status" id="status" color="primary" onChange={this.toggleStatus} />}
                            label={ data.status }
                            />}
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="space-between">
                            <Box width="24%">
                              <FormControl error={errors["windowSequence"] !== undefined} fullWidth>
                              <Autocomplete
                                  id="windowSequence"
                                  disableClearable
                                  autoHighlight={true}
                                  autoSelect={false}
                                  clearOnBlur={false}
                                  value={data.windowSequence}
                                  onChange={this.handleWindowSequenceChange}
                                  noOptionsText={"No Matching Window Sequences"}
                                  isOptionEqualToValue={(option, value) => option && value && option.searchableTitle === value.searchableTitle}
                                  getOptionLabel={(option) => option.searchableTitle}
                                  options={windowOptions}
                                  renderInput={(params) => (
                                    <TextFieldCore
                                      {...params}
                                      label="Window Sequence *"
                                      onChange={this.handleWindowSequenceInputTextChange}
                                      variant="outlined"
                                      error={errors && errors["windowSequence"] !== undefined}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              <FormHelperText>{errors && errors["windowSequence"]}</FormHelperText>
                               </FormControl>
                            </Box>
                            <Box width="24%">
                              <FormControl error={errors && errors["currency"] !== undefined} fullWidth>
                              <Autocomplete
                                  id="currency"
                                  disableClearable
                                  autoHighlight={true}
                                  autoSelect={false}
                                  clearOnBlur={false}
                                  value={data.currency}
                                  onChange={this.handleCurrencyChange}
                                  noOptionsText={"No Matching Currency"}
                                  getOptionLabel={(option) => option && option.searchableTitle}
                                  isOptionEqualToValue={(option, value) =>  option && value && option.searchableTitle === value.searchableTitle}
                                  options={currencyOptions}
                                  renderInput={(params) => (
                                    <TextFieldCore
                                      {...params}
                                      label="Currency *"
                                      onChange={this.handleCurrencyInputTextChange}
                                      variant="outlined"
                                      error={errors && errors["currency"] !== undefined}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                <FormHelperText>{errors && errors["currency"]}</FormHelperText>
                               </FormControl>
                            </Box>
                            <Box width="24%">
                              <FormControl error={errors["priceType"] !== undefined} fullWidth>
                                <Autocomplete
                                    id="priceType"
                                    disableClearable
                                    value={data.priceType ? data.priceType : ""}
                                    autoHighlight={true}
                                    autoSelect={false}
                                    clearOnBlur={false}
                                    onChange={this.handlePriceTypeChange}
                                    options={priceTypeOptions}
                                    renderInput={(params) => (
                                        <TextFieldCore
                                            {...params}
                                            label="Price Type *"
                                            variant="outlined"
                                            error={errors["priceType"] !== undefined}
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                  <React.Fragment>
                                                    {params.InputProps.endAdornment}
                                                  </React.Fragment>
                                              ),
                                            }}
                                        />
                                    )}
                                />
                                <FormHelperText>{errors["priceType"]}</FormHelperText>
                              </FormControl>
                            </Box>
                            <Box width="24%">
                              <FormControl error={errors["revShareType"] !== undefined} fullWidth>
                               <Autocomplete
                                  id="revShareType"
                                  disableClearable
                                  value={data.revShareType ? data.revShareType : ""}
                                  autoHighlight={true}
                                  autoSelect={false}
                                  clearOnBlur={false}
                                  onChange={(event, newValue) => {
                                    const { data } = self.state;
                                    data['revShareType'] = newValue
                                    errors["revShareType"] = undefined
                                    var headerColumns = this.buildTierHeaderUI(data)
                                    self.setState({ data, headerColumns });
                                  }}
                                  options={revShareOptions}
                                  renderInput={(params) => (
                                    <TextFieldCore
                                      {...params}
                                      label="Rev Share Type *"
                                      variant="outlined"
                                      error={errors["revShareType"] !== undefined}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                      />
                                    )}
                                  />
                               <FormHelperText>{errors["revShareType"]}</FormHelperText>
                              </FormControl>
                            </Box>
                          </Box>
                          <Typography style={{ fontSize: "18px "}} variant="subtitle1" color="inherit" >Tiers</Typography>
                         <div>
                            {data["windows"] && data["windows"].length > 0 && headerColumns && <Button
                              style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                              label="Add Tier Format"
                              onClick={this.addTierFormat}>
                                <IconPlus />
                            </Button>}
                            <Box display="flex" justifyContent="start" className="tierColumnHeaders">
                              {headerColumns && headerColumns.length === 0 && <span>No Tiers</span>}
                              {headerColumns && headerColumns.map(headerColumn => headerColumn)}
                            </Box>
                           {data["tierAliases"] && data["tierAliases"].length > 0 && data["windows"] && data["windows"].length > 0
                               && (data["priceType"] === 'License Fee' || (data["priceType"] === 'Min Deemed Price' && data["tierAliases"][0]["tiers"].length === 0))
                               && headerColumns && <Button
                                   style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                                   label="Add Tier Row"
                                   onClick={this.addTierRow}>
                                 <IconPlus />
                               </Button>}
                         </div>
                          <div style={{ marginBottom: '50px' }}/>
                          {errors}
                          </> }
                          <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '0', float: 'left', right: '0' }}>
                            <Button
                              style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                              label="Close"
                              onClick={this.handleCloseClick}>
                                <IconCancel />
                            </Button>
                            {false && this.props.id && this.props.id > 0 && data["status"] === "ACTIVE" && <Button
                              style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '50px', marginRight: '50px', color: '#F44336'}}
                              label="Inactivate"
                              onClick={this.inActivateRateCard}>
                                <IconDelete />
                            </Button>}
                            <Button
                              variant={'contained'}
                              color="primary"
                              style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '50px', marginRight: '50px'}}
                              label="Save"
                              onClick={this.validateFieldsAndSave}>
                                <IconSave />
                            </Button>
                            <Refresh/>
                          </div>
                    </Fragment>
                  </div>
              </Drawer>
          </Fragment>
        );
  }
}
const RateCardEdit = connect()(RateCardEditScreen);
export default RateCardEdit
